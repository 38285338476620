const slickOptions = {
  dots: false,
  infinite: true,
  speed: 300,
  adaptiveHeight: true,
  slidesToShow: 3, //images.length,
  slidesToScroll: 4, // images.length,
  nextArrow:
    '<button type="button" class="btn-next"><svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.7071 8.70711C30.0976 8.31659 30.0976 7.68342 29.7071 7.2929L23.3431 0.928936C22.9526 0.538412 22.3195 0.538412 21.9289 0.928936C21.5384 1.31946 21.5384 1.95263 21.9289 2.34315L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.70711ZM-1.74846e-07 9L29 9.00001L29 7.00001L1.74846e-07 7L-1.74846e-07 9Z" fill="#999999"></path></svg></button>',
  prevArrow:
    '<button type="button" class="btn-back"><svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.292893 7.29289C-0.097631 7.68341 -0.0976311 8.31658 0.292893 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31945 8.07107 0.928928C7.68054 0.538404 7.04738 0.538404 6.65686 0.928928L0.292893 7.29289ZM30 7L1 6.99999L1 8.99999L30 9L30 7Z" fill="#999999"></path></svg></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default slickOptions;
