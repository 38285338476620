<template>
  <div>
    <div v-if="images.length == 1" class="img-container">
      <img
        v-img
        class="img-fluid"
        :src="require(`@/assets/images/hobby/${images[0].url}`)"
        alt=""
      />
    </div>
    <div v-if="images.length > 1">
      <slick ref="slick" :options="slickOptions">
        <a href="#" v-for="image in images" :key="image.id" disable>
          <img
            v-img
            :class="['img-fluid', 'hobby-portfilio-image', type]"
            :src="require(`@/assets/images/hobby/${image.url}`)"
            alt=""
          />
        </a>
      </slick>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import slickOptions from "@/config/slickOptions";
//import Viewer from 'viewerjs';
export default {
  name: "hobbyImage",
  data: () => {
    return {
      slickOptions: slickOptions,
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "short",
    },
  },
  components: { Slick },
};
</script>

<style lang="scss">
.hobby-portfilio-image {
  width: 100%;
  object-fit: cover;
}
.hobby-portfilio-image.short {
  height: 200px;
}
.hobby-portfilio-image.long {
  height: 350px;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 10px;
}

.slick-arrow {
  position: sticky;
  top: 100%;
  // left: calc(50% - 100px);
}
.btn-next.slick-arrow {
  left: calc(50% + 10px);
}
.btn-back.slick-arrow {
  left: calc(50% - 40px);
}
.img-container {
  margin: auto;
  max-width: 80%;
}
</style>
