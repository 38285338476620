<template>
  <!-- other hobbies -->
  <page-structure title="hobbies" :loading="loading">
    <!-- architectural models -->
    <div class="row hobby">
      <div
        class="col-md-12 animate-box fadeInUp animated blog-single"
        data-animate-effect="fadeInUp"
      >
        <h3>Architectural models</h3>
        <p>
          The inspiration has been always for me like a creative impulse,
          without any art school background, since I know I liked to model, to
          sketch, in fact I wanted to become an architect, but it seems that
          other paths have been given to me to follow.
        </p>
        <p>
          Since 2002, without any access to the Internet or other digital
          technology, I had started to make cardboard models from various
          transport units. Then soon I discovered the cement processing
          technique, in order to use it as a raw material, just because
          cardboard seems to be weak to me. Thus, I managed to create my first
          structure quite rigid - LIGHTHOUSE, the result was beyond
          expectations, so I decided to continue. Several models of bridges
          followed, and the most opposing architectural model was the
          Gothic-Romanesque style cathedral, in 2006, being a reproduction of
          its own. In 2015 I made the last work of this kind, however the
          passion for creation continued in the following years in another form,
          that of painting, becoming a constant concern.
        </p>
        <p>
          What I concluded after many years dedicated to art, is that talent
          does not make you an artist, but work and dedication, believe me if
          you will be involved in development 16 hours a day, seven days a week,
          no doubt the results will show up, as Einstein said, “Genius is 1%
          talent and 99% work”.
        </p>
        <div class="row portfolio">
          <!-- <h5>My works</h5> -->
          <HobbyItem :items="items" />
        </div>
      </div>
    </div>
  </page-structure>
</template>

<script>
import HobbyItem from "@/components/hobby/HobbyItem";
export default {
  name: "hobbiesPage",
  data: () => {
    return {
      loading: true,
      items: [
        {
          title: "Bridge models",
          year: "2004 - 2006 (wood, steel, concrete. Up to 3 m length)",
          type: "short",
          images: [
            {
              id: 1,
              url: "2/1.jpg",
            },
            {
              id: 2,
              url: "2/2.jpg",
            },
            {
              id: 3,
              url: "2/3.jpg",
            },
            {
              id: 4,
              url: "2/4.jpg",
            },
          ],
        },
        {
          title: "Wooden Church",
          year: "2014 (pine wood)",
          type: "short",
          images: [
            {
              id: 1,
              url: "3/1.jpg",
            },
            {
              id: 2,
              url: "3/2.jpg",
            },
            {
              id: 3,
              url: "3/3.jpg",
            },
          ],
        },
        {
          title: "Gothic Cathedral",
          year: "2015 (balsa wood)",
          type: "long",
          images: [
            {
              id: 1,
              url: "1/1.jpg",
            },
            {
              id: 2,
              url: "1/2.jpg",
            },
            {
              id: 3,
              url: "1/3.jpg",
            },
            {
              id: 4,
              url: "1/4.jpg",
            },
            {
              id: 5,
              url: "1/5.jpg",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.loading = false;
  },
  components: {
    HobbyItem,
  },
  metaInfo: {
    title: "Hobbies",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
  },
};
</script>

<style>
.row.hobby {
  padding: 0px;
}
.row.portfolio {
  margin-top: 50px;
}
.row.hobby .blog-single {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
