<template>
  <div>
    <div class="row hobby-item" v-for="(item, idx) in items" :key="idx">
      <div class="row meta">
        <div class="row title">
          <p>{{ item.title }}</p>
        </div>
        <div class="row year">
          <p>{{ item.year }}</p>
        </div>
      </div>
      <div class="row galery">
        <HobbyImage :images="item.images" :type="item.type"></HobbyImage>
      </div>
    </div>
  </div>
</template>

<script>
import HobbyImage from "./HobbyImage";
export default {
  name: "hobby-item",
  data() {
    return {};
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    HobbyImage,
  },
};
</script>

<style>
.hobby-item {
  margin-top: 30px;
  margin-bottom: 30px;
}
.hobby-item .meta {
  /* margin-top: -15px;
  background-color: #252525; */
  padding: 10px;
}
.hobby-item div.row {
  width: 100%;
  text-align: center;
  margin: auto;
}
.hobby-item p,
.hobby-item div {
  margin-bottom: 0px;
}
.hobby-item .slick-initialized.slick-slider button {
  display: none !important;
}

.meta .row.title p,
.meta .row.year p {
  text-align: center;
}
.row.galery {
  padding: 0px;
}
</style>
